import type { FC, ReactNode } from "preact/compat";
import { useRef } from "preact/hooks";

export type ComboboxButtonGroupProps = {
  children: ReactNode;
};

export const ComboboxButtonGroup: FC<ComboboxButtonGroupProps> = ({
  children,
}) => {
  const buttonGroupRef = useRef<HTMLDivElement | null>(null);
  return (
    <div
      class="flex md:flex-row flex-col h-full items-center bg-gt-cream-light md:items-stretch border w-full md:w-fit border-gt-black md:border-none rounded-lg relative"
      role="group"
      ref={buttonGroupRef}
    >
      {children}
    </div>
  );
};
